<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Add User</div>
    </div>
    <div class="search-user-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                            <div class="input-group custom-input-group">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                        label="label" placeholder="Select" disabled
                                        class="multiselect-custom custom-title-multiselect" :canClear="false"
                                        :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                        :canDeselect="false" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <input type="text" v-model="searchmobile" class="form-control" id="formMobileNoInput"
                                        placeholder="Enter mobile No." maxlength="10" @keypress="onlyNumber"
                                        autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <label class="form-label"></label>
                            <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                                @click="searchuserbtn(searchmobile)" :disabled="searchmobile.length != 10">
                                <span v-if="!btnloader">Search</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="btnloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="searchMobileResult != ''">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="user-already-user-status">
                            <i class="pi pi-info-circle"></i> +91 {{ searchMobileResult.ad15 }} <span>this number has been
                                already set as a User</span>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="user-exit-box-outer mb-3">
                            <div class="row">
                                <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center user-profile-table-col
                        ">
                                    <div class="customer-label-group">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img v-if="searchMobileResult.ad22 == null || searchMobileResult.ad22 == ''"
                                                    src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                                                <img v-if="searchMobileResult.ad22 != null && searchMobileResult.ad22 != ''"
                                                    :src="'https://storage.googleapis.com/' + this.userstorageimgpath + '/host/userprofile/' + searchMobileResult.ad22"
                                                    class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      user-photo-img
                                    " alt="" style="width: 72px; height: 72px;" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12 user-name-table-col">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ searchMobileResult.ad9 ? searchMobileResult.ad9 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Mobile No.</label>
                                                <div class="from-label-value" v-if="isShowMobileNo == 0">
                                                    +91-{{ maskedNumber(searchMobileResult.ad15) }}
                                                </div>
                                                <div class="from-label-value" v-else-if="isShowMobileNo == 1">
                                                    +91-{{ searchMobileResult.ad15 }}
                                                </div>
                                                <div class="from-label-value" v-else>
                                                    {{ "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Branch Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    <span v-if="searchMobileResult.ad25">
                                                        <span class="branch-list-split me-2"
                                                            v-for="(value, index) in searchMobileResult.ad25.split(',')"
                                                            :key="index">{{ value }}</span>
                                                    </span>
                                                    <span v-else>
                                                        N/A
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Email Id</label>
                                                <div class="from-label-value" v-if="isShowEmail == 0">
                                                    {{ maskedEmail(searchMobileResult.ad18) }}
                                                </div>
                                                <div class="from-label-value" v-else-if="isShowEmail == 1">
                                                    {{ searchMobileResult.ad18 }}
                                                </div>
                                                <div class="from-label-value" v-else>
                                                    {{ "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Birth Date</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(searchMobileResult.ad12) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Area | Pincode</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ searchMobileResult.ad32 ? searchMobileResult.ad32 : "N/A" }}
                                                    <span>|</span>
                                                    {{ searchMobileResult.ad37 ? searchMobileResult.ad37 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-12 user-name-table-col">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2 d-flex
                                  justify-content-between">
                                                <div>
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                            searchMobileResult.ad14
                                                            ? searchMobileResult.ad14 == 1
                                                                ? "Male"
                                                                : searchMobileResult.ad14 == 2
                                                                    ? "Female"
                                                                    : "Others"
                                                            : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" title="Edit Details"
                                                        class="btn custom-outline-view-btn"
                                                        @click="userDetailEditModalOpen(searchMobileResult)">
                                                        <i class="pi pi-pencil call-btn-color"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Designation</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ searchMobileResult.ad11 ? searchMobileResult.ad11 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">City | State</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ searchMobileResult.ad34 ? searchMobileResult.ad34 : "N/A" }}
                                                    <span>|</span>
                                                    {{ searchMobileResult.ad36 ? searchMobileResult.ad36 : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Edit Details start here -->
    <div class="modal-mask" v-if="edituserdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Edit Details</h5>
                    <button type="button" class="btn-close" @click="userDetailEditModalClose()"></button>
                </div>
                <div class="px-4 pt-2">
                    <div class="stepper-label-edit-voter">
                        <span v-if="userEditStepFirstStatus">Step<span class="stepper-label-circle-voter">1</span></span>
                        <span v-if="userEditStepTwoStatus">Step<span class="stepper-label-circle-voter">2</span></span>
                        <span v-if="userEditStepThrirdStatus">Step<span class="stepper-label-circle-voter">3</span></span>
                    </div>
                </div>
                <Divider />
                <!-- First Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userEditStepFirstStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="edit.countrycode" :options="countryCodeList"
                                                    :searchable="true" label="label" placeholder="Select" disabled
                                                    class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                    :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                                    :canDeselect="false" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" v-model="edit.primobile" class="form-control"
                                                    id="formMobileNoInput" disabled placeholder="Enter mobile No."
                                                    maxlength="10" @keypress="onlyNumber" autocomplete="off" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.edit.primobile.$error">
                                            {{ v$.edit.primobile.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formTitleInput" class="form-label">First Name<span
                                                class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="edit.title" :disabled="edituserloader"
                                                    :options="titleList" :searchable="false" label="label"
                                                    placeholder="Select"
                                                    class="multiselect-custom custom-title-multiselect text-capitalize"
                                                    :canClear="false" :closeOnSelect="true" :object="true"
                                                    noOptionsText="No Result" :canDeselect="false"
                                                    @select="handleEditGender" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" v-model="edit.firstname" :disabled="edituserloader"
                                                    class="form-control text-capitalize" id="formFirstNameInput"
                                                    placeholder="Enter First Name" autocomplete="off" maxlength="20"
                                                    @keypress="isLetterWithOutSpace($event)" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.edit.firstname.$error">
                                            {{ v$.edit.firstname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Middle Name</label>
                                        <input type="text" v-model="edit.middlename" :disabled="edituserloader"
                                            class="form-control text-capitalize" id="formLastNameInput"
                                            placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                                            @keypress="isLetterWithOutSpace($event)" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Last Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="edit.lastname" :disabled="edituserloader"
                                            class="form-control text-capitalize" id="formLastNameInput"
                                            placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                                            @keypress="isLetterWithOutSpace($event)" />
                                        <div class="custom-error" v-if="v$.edit.lastname.$error">
                                            {{ v$.edit.lastname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Email Id</label>
                                        <input type="text" v-model="edit.email" :disabled="edituserloader"
                                            class="form-control" id="formEmailInput" placeholder="Enter Email Id"
                                            autocomplete="off" maxlength="50" />
                                        <div class="custom-error" v-if="v$.edit.email.$error">
                                            {{ v$.edit.email.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Gender<span class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.gender" :disabled="edituserloader" :options="genderList"
                                            :searchable="false" label="label" placeholder="Select"
                                            class="multiselect-custom" :canClear="false" :closeOnSelect="true"
                                            :object="true" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.gender.$error">
                                            {{ v$.edit.gender.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Birth Date<span class="text-danger">*</span></label>
                                        <Calendar v-model="edit.birthdate" :disabled="edituserloader"
                                            :maxDate="maxDateBirth" :showIcon="true" class="custom-ultima-calendar"
                                            placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                                            :monthNavigator="true" showButtonBar
                                            :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body"
                                            :yearNavigator="true">
                                        </Calendar>
                                        <div class="custom-error" v-if="v$.edit.birthdate.$error">
                                            {{ v$.edit.birthdate.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Select Designation<span
                                                class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.designation" :disabled="edituserloader"
                                            :options="designationList" :searchable="true" label="label"
                                            placeholder="Search | Add Designation"
                                            class="multiselect-custom text-capitalize" :canClear="true"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                                            :createOption="true" :addOptionOn="['enter', 'tab']"
                                            @select="createVoterEditDesignation($event)" />
                                        <div class="custom-error" v-if="v$.edit.designation.$error">
                                            {{ v$.edit.designation.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput"
                                            class="form-label d-flex justify-content-between"><span>Select Branch<span
                                                    class="text-danger">*</span></span><span
                                                class="small ms-1 text-muted">(Max 5)</span></label>
                                        <Multiselect v-model="edit.branch" :disabled="edituserloader" :options="branchList"
                                            :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" :object="true" mode="tags" :createTag="false" :max="5"
                                            noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.branch.$error">
                                            {{ v$.edit.branch.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select State<span class="text-danger">*</span></label>
                                        <Multiselect v-model="edit.state" :disabled="edituserloader" :options="stateList"
                                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                                            :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            @keypress="isLetterWithSpace($event)" @select="getCityByStateId(edit.state)"
                                            required />
                                        <div class="custom-error" v-if="v$.edit.state.$error">
                                            {{ v$.edit.state.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select City<span class="text-danger">*</span></label>
                                        <Multiselect v-show="!cityloaderflag" :disabled="edituserloader" v-model="edit.city"
                                            :options="cityList" :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                                            :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true"
                                            @select="getAreaByCityId(edit.city)" required />
                                        <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.city.$error">
                                            {{ v$.edit.city.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Area<span class="text-danger">*</span></label>
                                        <Multiselect v-show="!arealoaderflag" :disabled="edituserloader" v-model="edit.area"
                                            :options="areaList" :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom" @keypress="isLetterWithSpace($event)"
                                            :canClear="true" :closeOnSelect="true" noOptionsText="No Result" :object="true"
                                            required />
                                        <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.edit.area.$error">
                                            {{ v$.edit.area.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label for="formShortNameInput" class="form-label">Address<span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" :disabled="edituserloader" v-model="edit.fulladdress"
                                            class="form-control custom-text-area-scroll" placeholder="Enter Full Address"
                                            autocomplete="off" rows="2" maxlength="250"></textarea>
                                        <div class="custom-error" v-if="v$.edit.fulladdress.$error">
                                            {{ v$.edit.fulladdress.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Pin Code<span class="text-danger">*</span></label>
                                        <input type="text" :disabled="edituserloader" v-model="edit.pincode"
                                            class="form-control text-capitalize" placeholder="Enter Pin Code" maxlength="6"
                                            @keypress="onlyNumber" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.edit.pincode.$error">
                                            {{ v$.edit.pincode.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                                        <input type="text" :disabled="edituserloader" v-model="edit.landmark"
                                            class="form-control text-capitalize" id="formLandmarkInput"
                                            placeholder="Enter Landmark" autocomplete="off" maxlength="50" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Profile</label>
                                        <FileUpload :disabled="edituserloader" mode="basic" ref="userprofileimg"
                                            class="custom-upload-btn-ultima" v-model="edit.userprofileimg"
                                            v-on:change="handleUploaduserProfile()" />
                                        <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                                    <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''"
                                        :src="edit.importprofilefile" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    user-photo-img
                                  " alt="" style="width: 72px; height: 72px;" />
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                                    <img :src="exitpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    user-photo-img
                                  " alt="" style="width: 72px; height: 72px;" />
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userEditStepFirstStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateuserDetailbtn()">
                            <span v-if="!edituserloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="edituserloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- First Stepper End Here -->
                <!-- Second Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userEditStepTwoStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Role Type<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" :disabled="edituserloader" class="btn-check"
                                                v-model="edituser.roletype" name="role-option" id="rolewise"
                                                autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="rolewise">Role
                                                Wise</label>
                                            <input type="radio" :disabled="edituserloader" class="btn-check"
                                                v-model="edituser.roletype" name="role-option" id="manualrole"
                                                autocomplete="off" value="2" />
                                            <label class="btn text-capitalize" for="manualrole">Manual Permission</label>
                                            <div class="custom-error" v-if="v$.edituser.roletype.$error">
                                                {{ v$.edituser.roletype.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="edituser.roletype == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Role<span class="text-danger">*</span></label>

                                        <Multiselect :disabled="edituserloader" v-model="edituser.userrole"
                                            :options="userrole_list" :searchable="true" label="label"
                                            placeholder="Select User Role" class="multiselect-custom text-capitalize"
                                            :canClear="true" :closeOnSelect="true" />
                                        <div class="custom-error"
                                            v-if="v$.edituser.userrole.$error && edituser.roletype == 1">
                                            {{ v$.edituser.userrole.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="edituser.roletype == 2">
                                <div class="col-12">
                                    <div class="flex flex-wrap gap-2 mb-2">
                                        <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll()">
                                            <i class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All
                                        </button>
                                        <button type="button" class="btn custom-view-detail-btn" @click="collapseAll()">
                                            <i class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All
                                        </button>
                                    </div>
                                    <Tree :value="nodes" :disabled="edituserloader" selectionMode="checkbox"
                                        v-model:selectionKeys="edituser.selectedTreeValue" :expandedKeys="expandedKeys">
                                    </Tree>
                                    <div class="custom-img-error"
                                        v-if="v$.edituser.selectedTreeValue.$error && edituser.roletype == 2">
                                        {{ v$.edituser.selectedTreeValue.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userEditStepTwoStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateUserPermissionbtn()">
                            <span v-if="!edituserloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="edituserloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Second Stepper End Here -->
                <!-- Third Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userEditStepThrirdStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Mobile Number : </label>
                                        <InputSwitch :disabled="editpermissionloader" v-model="allow.usermobpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Email Id : </label>
                                        <InputSwitch :disabled="editpermissionloader" v-model="allow.useremailpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Aadhar Number : </label>
                                        <InputSwitch :disabled="editpermissionloader" v-model="allow.useraadharpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Use Mobile App : </label>
                                        <InputSwitch :disabled="editpermissionloader"
                                            v-model="allow.usermobapppermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between customer-label-group">
                                        <label class="from-label-value me-3">Allow To Edit User Permission : </label>
                                        <InputSwitch :disabled="editpermissionloader" v-model="allow.usereditpermission" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userEditStepThrirdStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserPermission()">
                            <span v-if="!editpermissionloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="editpermissionloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Third Stepper End Here -->
            </div>
        </div>
    </div>
    <!-- Edit Details end here -->
    <!-- search user alert modal start here -->
    <div class="modal-mask" v-if="usersearchalertmodals">
        <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Alert</h5>
                    <button type="button" class="btn-close" @click="closeSearchAlertModal()"></button>
                </div>
                <div class="modal-body text-center alert-body-bg">
                    <div class="user-alert-group">
                        <div class="mb-4">+91 {{ this.ad15 }} Mobile Number has been not Registered in User list!</div>
                        <div>If you want to add new user click on "Create" button</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 text-center">
                        <button type="button" class="btn modal-bulk-next-btn" @click="registerusermodalopen(this.ad15)">
                            <span v-if="!createuserloader">Create</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="createuserloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- search user alert modal end here -->
    <!-- Create Mobile No Via user Detail start here -->
    <div class="modal-mask" v-if="createuserdetailstatus">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header custom-custmer-header-border">
                    <h5 class="modal-title" id="staticBackdropLabel">Create User</h5>
                    <button type="button" class="btn-close" @click="userRegistrationModalClose()"></button>
                </div>
                <!-- Create user Stepper First start here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userAddStepFirstStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="add.countrycode" :options="countryCodeList"
                                                    :searchable="true" label="label" placeholder="Select" disabled
                                                    class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                    :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                                    :canDeselect="false" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" v-model="add.primobile" class="form-control"
                                                    id="formMobileNoInput" disabled placeholder="Enter mobile No."
                                                    maxlength="10" @keypress="onlyNumber" autocomplete="off" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.add.primobile.$error">
                                            {{ v$.add.primobile.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formTitleInput" class="form-label">First Name<span
                                                class="text-danger">*</span></label>
                                        <div class="input-group custom-input-group">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <Multiselect v-model="add.title" :disabled="adduserregloader"
                                                    :options="titleList" :searchable="false" label="label"
                                                    placeholder="Select" class="multiselect-custom custom-title-multiselect"
                                                    :canClear="false" :closeOnSelect="true" :object="true"
                                                    noOptionsText="No Result" :canDeselect="false"
                                                    @select="handleAddGender" />
                                            </div>
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <input type="text" :disabled="adduserregloader" v-model="add.firstname"
                                                    class="form-control text-capitalize" id="formFirstNameInput"
                                                    placeholder="Enter First Name" autocomplete="off" maxlength="20"
                                                    @keypress="isLetterWithOutSpace($event)" />
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.add.firstname.$error">
                                            {{ v$.add.firstname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Middle Name</label>
                                        <input type="text" :disabled="adduserregloader" v-model="add.middlename"
                                            class="form-control text-capitalize" id="formLastNameInput"
                                            placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                                            @keypress="isLetterWithOutSpace($event)" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLastNameInput" class="form-label">Last Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" :disabled="adduserregloader" v-model="add.lastname"
                                            class="form-control text-capitalize" id="formLastNameInput"
                                            placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                                            @keypress="isLetterWithOutSpace($event)" />
                                        <div class="custom-error" v-if="v$.add.lastname.$error">
                                            {{ v$.add.lastname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Email Id</label>
                                        <input type="text" :disabled="adduserregloader" v-model="add.email"
                                            class="form-control" id="formEmailInput" placeholder="Enter Email Id"
                                            autocomplete="off" maxlength="50" />
                                        <div class="custom-error" v-if="v$.add.email.$error">
                                            {{ v$.add.email.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Gender<span class="text-danger">*</span></label>
                                        <Multiselect v-model="add.gender" :disabled="adduserregloader" :options="genderList"
                                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                                            :canClear="false" :closeOnSelect="true" :object="true"
                                            noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.add.gender.$error">
                                            {{ v$.add.gender.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formBirthDateInput" class="form-label">Birth Date<span
                                                class="text-danger">*</span></label>
                                        <Calendar v-model="add.birthdate" :disabled="adduserregloader"
                                            :maxDate="maxDateBirth" :showIcon="true" class="custom-ultima-calendar"
                                            placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                                            :monthNavigator="true" :yearRange="'1950:' + maxDateBirth.getFullYear()"
                                            appendTo="body" :yearNavigator="true">
                                        </Calendar>
                                        <div class="custom-error" v-if="v$.add.birthdate.$error">
                                            {{ v$.add.birthdate.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput" class="form-label">Select Designation<span
                                                class="text-danger">*</span></label>
                                        <Multiselect v-model="add.designation" :disabled="adduserregloader"
                                            :options="designationList" :searchable="true" label="label"
                                            placeholder="Search | Add Designation"
                                            class="multiselect-custom text-capitalize" :canClear="true"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true"
                                            :createOption="true" :addOptionOn="['enter', 'tab']"
                                            @select="createVoterAddDesignation($event)" />
                                        <div class="custom-error" v-if="v$.add.designation.$error">
                                            {{ v$.add.designation.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label for="formEmailInput"
                                            class="form-label d-flex justify-content-between"><span>Select Branch<span
                                                    class="text-danger">*</span></span><span
                                                class="small ms-1 text-muted">(Max 5)</span></label>
                                        <Multiselect v-model="add.branch" :disabled="adduserregloader" :options="branchList"
                                            :searchable="true" label="label" placeholder="Select"
                                            class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" :object="true" mode="tags" :createTag="false" :max="5"
                                            noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.add.branch.$error">
                                            {{ v$.add.branch.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select State<span class="text-danger">*</span></label>
                                        <Multiselect v-model="add.state" :disabled="adduserregloader" :options="stateList"
                                            :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                                            :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            @keypress="isLetterWithSpace($event)" @select="getCityByStateId(add.state)"
                                            required />
                                        <div class="custom-error" v-if="v$.add.state.$error">
                                            {{ v$.add.state.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select City<span class="text-danger">*</span></label>
                                        <Multiselect v-show="!cityloaderflag" :disabled="adduserregloader"
                                            v-model="add.city" :options="cityList" :searchable="true" label="label"
                                            placeholder="Select" class="multiselect-custom"
                                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                                            noOptionsText="No Result" :object="true" @select="getAreaByCityId(add.city)"
                                            required />
                                        <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.add.city.$error">
                                            {{ v$.add.city.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Area<span class="text-danger">*</span></label>
                                        <Multiselect v-show="!arealoaderflag" :disabled="adduserregloader"
                                            v-model="add.area" :options="areaList" :searchable="true" label="label"
                                            placeholder="Select" class="multiselect-custom"
                                            @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                                            noOptionsText="No Result" :object="true" required />
                                        <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label"
                                            placeholder="Select" class="multiselect-custom" noOptionsText="No Result" />
                                        <div class="custom-error" v-if="v$.add.area.$error">
                                            {{ v$.add.area.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="custom-form-group">
                                        <label for="formShortNameInput" class="form-label">Address<span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" :disabled="adduserregloader" v-model="add.fulladdress"
                                            class="form-control custom-text-area-scroll" placeholder="Enter Full Address"
                                            autocomplete="off" rows="2" maxlength="250"></textarea>
                                        <div class="custom-error" v-if="v$.add.fulladdress.$error">
                                            {{ v$.add.fulladdress.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Pin Code<span class="text-danger">*</span></label>
                                        <input type="text" :disabled="adduserregloader" v-model="add.pincode"
                                            class="form-control text-capitalize" placeholder="Enter Pin Code" maxlength="6"
                                            @keypress="onlyNumber" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.add.pincode.$error">
                                            {{ v$.add.pincode.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                                        <input type="text" v-model="add.landmark" :disabled="adduserregloader"
                                            class="form-control text-capitalize" id="formLandmarkInput"
                                            placeholder="Enter Landmark" autocomplete="off" maxlength="50" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Profile</label>
                                        <FileUpload :disabled="adduserregloader" mode="basic" ref="usercreateimg"
                                            class="custom-upload-btn-ultima" v-model="add.usercreateimg"
                                            v-on:change="handleUploaduserCreate()" />
                                        <div class="custom-error" v-if="errorprocreateimage">{{ errorprocreateimage }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="createpreviewurl != null && createpreviewurl != ''"></div>
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="createpreviewurl != null && createpreviewurl != ''"></div>
                                <div class="col-lg-4 col-md-4 col-12"
                                    v-if="createpreviewurl != null && createpreviewurl != ''">
                                    <img :src="createpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    user-photo-img
                                  " alt="" />
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userAddStepFirstStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="CreateuserDetailbtn()">
                            <span v-if="!adduserregloader">Next</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="adduserregloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create user Stepper First end here -->
                <!-- Create user Stepper Second start here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userAddStepSecondStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Role Type<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" :disabled="adduserregloader" class="btn-check"
                                                v-model="adduser.roletype" name="role-option" id="rolewise"
                                                autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="rolewise">Role
                                                Wise</label>
                                            <input type="radio" :disabled="adduserregloader" class="btn-check"
                                                v-model="adduser.roletype" name="role-option" id="manualrole"
                                                autocomplete="off" value="2" />
                                            <label class="btn text-capitalize" for="manualrole">Manual Permission</label>
                                            <div class="custom-error" v-if="v$.adduser.roletype.$error">
                                                {{ v$.adduser.roletype.$errors[0].$message }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12" v-if="adduser.roletype == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Role<span class="text-danger">*</span></label>

                                        <Multiselect :disabled="adduserregloader" v-model="adduser.userrole"
                                            :options="userrole_list" :searchable="true" label="label"
                                            placeholder="Select User Role" class="multiselect-custom text-capitalize"
                                            :canClear="true" :closeOnSelect="true" />
                                        <div class="custom-error" v-if="v$.adduser.userrole.$error && roletype == 1">
                                            {{ v$.adduser.userrole.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="adduser.roletype == 2">
                                <div class="col-12">
                                    <div class="flex flex-wrap gap-2 mb-2">
                                        <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll()">
                                            <i class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All
                                        </button>
                                        <button type="button" class="btn custom-view-detail-btn" @click="collapseAll()">
                                            <i class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All
                                        </button>
                                    </div>
                                    <Tree :value="nodes" selectionMode="checkbox" :disabled="adduserregloader"
                                        v-model:selectionKeys="adduser.selectedTreeValue" :expandedKeys="expandedKeys">
                                    </Tree>
                                    <div class="custom-error"
                                        v-if="v$.adduser.selectedTreeValue.$error && adduser.roletype == 2">
                                        {{ v$.adduser.selectedTreeValue.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userAddStepSecondStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="CreateUserPermissionbtn()">
                            <span v-if="!adduserregloader">
                                <span>Next</span>
                            </span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="adduserregloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create user Stepper Second end here -->
                <!-- Create user Stepper Third Stepper Start Here -->
                <div class="modal-body modal-body-scroll-outer" v-if="userAddStepThirdStatus">
                    <div style="position: relative;
    min-height: 443px;">
                        <div v-if="!showloaderusermodal">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Mobile Number : </label>
                                        <InputSwitch :disabled="addpermissionloader" v-model="addallow.usermobpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Email Id : </label>
                                        <InputSwitch :disabled="addpermissionloader"
                                            v-model="addallow.useremailpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Show Aadhar Number : </label>
                                        <InputSwitch :disabled="addpermissionloader"
                                            v-model="addallow.useraadharpermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div
                                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                                        <label class="from-label-value me-3">Allow To Use Mobile App : </label>
                                        <InputSwitch :disabled="addpermissionloader"
                                            v-model="addallow.usermobapppermission" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="d-flex align-items-center justify-content-between customer-label-group">
                                        <label class="from-label-value me-3">Allow To Edit User Permission : </label>
                                        <InputSwitch :disabled="addpermissionloader"
                                            v-model="addallow.usereditpermission" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showloaderusermodal">
                            <div class="custom-modal-spinner-loader">
                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="userAddStepThirdStatus">
                    <div class="col-12 text-end">
                        <button type="button" class="btn modal-bulk-next-btn" @click="addClientUserPermission()">
                            <span v-if="!addpermissionloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="addpermissionloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Create user Stepper Third Stepper End Here -->
            </div>
        </div>
    </div>
    <!-- Create Mobile No Via user Detail end here -->
</template>
<script>
import commonMixin from "../../../mixins/commonMixin.js"
import { required, helpers, email, numeric, minLength, requiredIf, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
import moment from 'moment';
export default {
    mixins: [commonMixin],
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            btnloader: false,
            edituserdetailstatus: false,
            adduserloader: false,
            errorprofileimage: '',
            showloaderusermodal: false,
            genderList: [
                { label: "Male", value: 1 },
                { label: "Female", value: 2 },
                { label: "Other", value: 3 },
            ],
            titleList: [],
            designationList: [],
            branchList: [],
            maxDateBirth:new Date(),
            userrole_list: [],
            expandedKeys: {},
            nodes: [],
            usersearchalertmodals: false,
            createuserloader: false,
            searchMobileResult: '',
            createuserdetailstatus: false,
            showloaderuserregmodal: false,
            edit: {
                birthdate: null,
                designation: '',
                branch: [],
                importprofilefile: '',
                editHostId: '',
                userprofileimg: '',
                title: { value: 1, label: 'Mr.' },
                firstname: '',
                middlename: '',
                lastname: '',
                email: '',
                gender: '',
                primobile: "",
                countrycode: { value: 101, label: "+91" },
                fulladdress: '',
                state: { value: 11, label: "Goa" },
                city: "",
                area: "",
                pincode: '',
                landmark: '',
            },
            edituser: {
                roletype: '',
                userrole: "",
                selectedTreeValue: [],
            },
            add: {
                title: { value: 1, label: "Mr." },
                firstname: "",
                middlename: "",
                lastname: "",
                countrycode: { value: 101, label: "+91" },
                primobile: "",
                email: "",
                gender: "",
                birthdate: null,
                designation: "",
                branch: [],
                usercreateimg: '',
                fulladdress: '',
                state: { value: 11, label: "Goa" },
                city: "",
                area: "",
                pincode: '',
                landmark: '',
            },
            adduser: {
                roletype: '',
                userrole: "",
                selectedTreeValue: [],
            },
            stateList: [],
            cityList: [],
            areaList: [],
            errorprocreateimage: '',
            edituserloader: false,
            userEditStepFirstStatus: false,
            userEditStepTwoStatus: false,
            userEditStepThrirdStatus: false,
            userAddStepFirstStatus: false,
            userAddStepSecondStatus: false,
            userAddStepThirdStatus: false,
            adduserregloader: false,
            newhostid: '',
            cityloaderflag: false,
            arealoaderflag: false,
            client_info: '',
            userstorageimgpath: '',
            editpermissionloader: false,
            allow: {
                usermobpermission: '',
                useremailpermission: '',
                useraadharpermission: '',
                usermobapppermission: '',
                usereditpermission: '',
            },
            addallow: {
                usermobpermission: '',
                useremailpermission: '',
                useraadharpermission: '',
                usermobapppermission: '',
                usereditpermission: '',
            },
            createpreviewurl: null,
            exitpreviewurl: null,
            user_storage_fk: '',
            user_info: '',
            allowedituserfk: '',
            addpermissionloader: false,
            usertypeedit: '',
            usertypeadd: 2,
            hostId: '',
            existUserId: '',
            isShowMobileNo: '',
            isShowEmail: ''
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            edit: {
                primobile: {
                    required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
                        "Mobile no should be min 10 digit",
                        minLength(10)
                    ), maxLength: maxLength(10)
                },
                firstname: {
                    required: helpers.withMessage("Please enter first name", required),
                },
                lastname: {
                    required: helpers.withMessage("Please enter last name", required),
                },
                email: {
                    email: helpers.withMessage('Email address is invalid', email)
                },
                birthdate: { required: helpers.withMessage('Please select birthdate', required) },
                gender: { required: helpers.withMessage('Please select gender', required) },
                designation: { required: helpers.withMessage('Please select designation', required) },
                branch: { required: helpers.withMessage('Please select branch', required) },
                fulladdress: { required: helpers.withMessage('Please enter full address', required) },
                state: { required: helpers.withMessage('Please select state', required) },
                city: { required: helpers.withMessage('Please select city', required) },
                area: { required: helpers.withMessage('Please select area', required) },
                pincode: {
                    required: helpers.withMessage('Please enter pincode', required),
                    minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
            },
            edituser: {
                roletype: { required: helpers.withMessage("Please select role type", required), },
                userrole: {
                    requiredIf: helpers.withMessage(
                        "Please select role",
                        requiredIf(
                            this.edituser.roletype == 1
                        )
                    ),
                },
                selectedTreeValue: {
                    requiredIf: helpers.withMessage(
                        "Please select module",
                        requiredIf(
                            this.edituser.roletype == 2
                        )
                    ),
                },
            },
            add: {
                firstname: { required: helpers.withMessage('Please enter first name', required) },
                lastname: { required: helpers.withMessage('Please enter last name', required) },
                primobile: {
                    required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
                        "Mobile no should be min 10 digit",
                        minLength(10)
                    ), maxLength: maxLength(10)
                },
                email: { email: helpers.withMessage('Email address is invalid', email) },
                birthdate: { required: helpers.withMessage('Please select birthdate', required) },
                gender: { required: helpers.withMessage('Please select gender', required) },
                designation: { required: helpers.withMessage('Please select designation', required) },
                branch: { required: helpers.withMessage('Please select branch', required) },
                fulladdress: { required: helpers.withMessage('Please enter full address', required) },
                state: { required: helpers.withMessage('Please select state', required) },
                city: { required: helpers.withMessage('Please select city', required) },
                area: { required: helpers.withMessage('Please select area', required) },
                pincode: {
                    required: helpers.withMessage('Please enter pincode', required),
                    minLength: helpers.withMessage(
                        "Pincode should be min 6 length",
                        minLength(6)
                    ),
                },
            },
            adduser: {
                roletype: { required: helpers.withMessage("Please select role type", required), },
                userrole: {
                    requiredIf: helpers.withMessage(
                        "Please select role",
                        requiredIf(
                            this.adduser.roletype == 1
                        )
                    ),
                },
                selectedTreeValue: {
                    requiredIf: helpers.withMessage(
                        "Please select module",
                        requiredIf(
                            this.adduser.roletype == 2
                        )
                    ),
                },
            },
        };
    },
    mounted() {
        this.expandAll();
        this.client_info = JSON.parse(localStorage.client_info);
        this.userstorageimgpath = this.client_info.maa24;
        this.user_info = JSON.parse(localStorage.user);
        this.user_storage_fk = this.user_info.user_id;
        this.allowedituserfk = this.user_info.ak109;
        this.hostId = this.user_info.ad2;
        this.isShowMobileNo = this.user_info.ad39;
        this.isShowEmail = this.user_info.ad40;
    },
    methods: {
        getModules(ev) {
            this.ApiService.getModules(ev).then(data => {
                if (data.status == 200) {
                    this.nodes = data.data;
                }
            })
        },
        CreateuserDetailbtn() {
            this.submitted = true;
            this.errormsg = '';
            this.v$.add.$validate();
            var formData = new FormData();
            formData.append('ad4', this.add.title.value);
            formData.append('ad5', this.add.title.label);
            formData.append('ad6', this.add.firstname);
            if (this.add.middlename) {
                formData.append("ad7", this.add.middlename);
            }
            formData.append('ad8', this.add.lastname);
            var fullname = this.add.title.label + ' ' + this.add.firstname + ' ' + this.add.middlename + ' ' + this.add.lastname;
            formData.append('ad9', fullname);
            formData.append('ad15', this.add.primobile);
            if (this.add.email) {
                formData.append("ad18", this.add.email);
            }
            formData.append('ad12', moment(this.add.birthdate).format("YYYY-MM-DD"));
            formData.append('ad14', this.add.gender.value);
            formData.append('ad30', this.add.fulladdress);
            formData.append('ad37', this.add.pincode);
            formData.append('ad38', this.add.landmark);
            formData.append("ad35", this.add.state.value);
            formData.append("ad36", this.add.state.label);
            formData.append("ad33", this.add.city.value);
            formData.append("ad34", this.add.city.label);
            formData.append("ad31", this.add.area.value);
            formData.append("ad32", this.add.area.label);
            if (this.add.designation) {
                formData.append("ad10", this.add.designation.value);
                formData.append("ad11", this.add.designation.label);
            }
            if (this.add.branch) {
                formData.append('branchlist', JSON.stringify(this.add.branch));
            }
            if (this.add.usercreateimg) {
                formData.append("userProfileImage", this.add.usercreateimg);
            }
            formData.append('ad23', this.usertypeadd);
            if (!this.v$.add.$error) {
                this.adduserregloader = true;
                this.ApiService.addOrUpdateUser(formData).then((data) => {
                    if (data.status == 200) {
                        this.adduserregloader = false;
                        var successMsg = data.message;
                        this.newhostid = data.data;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.userAddStepFirstStatus = false;
                        this.userAddStepSecondStatus = true;
                        this.showloader = false;
                        this.add.middlename = '';
                        this.add.lastname = '';
                        this.add.email = '';
                        this.add.gender = '';
                        this.add.birthdate = '';
                        this.add.designation = '';
                        this.add.usercreateimg = null;
                        this.add.fulladdress = '';
                        this.add.pincode = '';
                        this.add.landmark = '';
                        this.add.city = '';
                        this.add.area = '';
                        this.createpreviewurl = null;
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.adduserregloader = false;
                        this.userAddStepFirstStatus = false;
                    }
                });
            } else {
                this.$error;
            }
        },
        CreateUserPermissionbtn() {
            this.v$.adduser.$validate();
            let fields = {};
            fields["ad1"] = this.newhostid;
            fields["ad15"] = this.add.primobile;
            fields["ad6"] = this.add.firstname;
            if (this.adduser.roletype) {
                fields["role_type"] = this.adduser.roletype;
            }
            if (this.adduser.roletype == 1) {
                fields["ad26"] = this.adduser.userrole;
            }
            if (this.adduser.roletype == 2) {
                fields["modules"] = this.adduser.selectedTreeValue;
            }
            if (!this.v$.adduser.$error) {
                this.adduserregloader = true;
                this.ApiService.updateUserPermissions(fields).then((items) => {
                    if (items.status == 200) {
                        this.adduserregloader = false;
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.add.primobile = '';
                        this.add.firstname = '';
                        this.searchmobile = '';
                        this.userAddStepSecondStatus = false;
                        this.userAddStepThirdStatus = true;
                    } else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.adduserregloader = false;
                        this.userAddStepSecondStatus = false;
                    }
                });
            }
        },
        addClientUserPermission() {
            let fields = {};
            fields["ad1"] = this.newhostid;
            if (this.addallow.usermobpermission == true) {
                fields["ad39"] = 1;
            } else if (this.addallow.usermobpermission == false) {
                fields["ad39"] = 0;
            }

            if (this.addallow.useremailpermission == true) {
                fields["ad40"] = 1;
            } else if (this.addallow.useremailpermission == false) {
                fields["ad40"] = 0;
            }

            if (this.addallow.useraadharpermission == true) {
                fields["ad47"] = 1;
            } else if (this.addallow.useraadharpermission == false) {
                fields["ad47"] = 0;
            }

            if (this.addallow.usermobapppermission == true) {
                fields["ad41"] = 1;
            } else if (this.addallow.usermobapppermission == false) {
                fields["ad41"] = 0;
            }

            if (this.addallow.usereditpermission == true) {
                fields["ad42"] = 1;
            } else if (this.addallow.usereditpermission == false) {
                fields["ad42"] = 0;
            }
            this.addpermissionloader = true;
            this.ApiService.updateuserallowpermissions(fields).then((items) => {
                if (items.status == 200) {
                    this.addpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.userEditStepThrirdStatus = false;
                    this.createuserdetailstatus = false;
                    if (this.newhostid == this.user_storage_fk) {
                        this.logout();
                    } else {
                        this.redirecttouserlisting();
                    }
                } else {
                    this.addpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        getcountrycode(e) {
            this.ApiService.getcountrycode(e).then((data) => {
                if (data.status == 200) {
                    this.countryCodeList = data.data;
                }
            });
        },
        getalltitle(e) {
            this.ApiService.getalltitle(e).then((data) => {
                if (data.status == 200) {
                    this.titleList = data.data;
                }
            });
        },
        getUserDesignations(e) {
            this.ApiService.getUserDesignations(e).then((data) => {
                if (data.status == 200) {
                    this.designationList = data.data;
                }
            });
        },
        getstates() {
            this.ApiService.getstates().then((data) => {
                if (data.success == true) {
                    this.stateList = data.data;
                    this.showloaderusermodal = false;
                }
            });
        },
        getLoadCityApi() {
            this.cityloaderflag = true;
            this.ApiService.getcities({ state_id: this.add.state.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = null;
                }
            });
        },
        getCityByStateId(e) {
            this.cityloaderflag = true;
            this.add.city = '';
            this.add.area = '';
            this.edit.city = '';
            this.edit.area = '';
            this.ApiService.getcities({ state_id: e.value }).then((data) => {
                if (data.success == true) {
                    this.cityList = data.data;
                    this.cityloaderflag = false;
                } else {
                    this.cityloaderflag = false;
                    this.cityList = [];
                }
            });
        },
        getAreaByCityId(e) {
            this.arealoaderflag = true;
            this.add.area = '';
            this.edit.area = '';
            this.ApiService.getareas({ city_id: e.value }).then((data) => {
                if (data.success == true) {
                    this.areaList = data.data;
                    this.arealoaderflag = false;
                } else {
                    this.arealoaderflag = false;
                    this.areaList = [];
                }
            });
        },
        searchuserbtn(mobilenumber) {
            this.btnloader = true;
            this.ad15 = mobilenumber;
            this.ApiService.getUserUsingMobileNumber({ ad15: this.ad15 }).then((data) => {
                if (data.status == 200) {
                    this.searchMobileResult = data.data;
                    this.btnloader = false;
                } else {
                    this.searchMobileResult = '';
                    this.usersearchalertmodals = true;
                    this.btnloader = false;
                }
            });
        },
        registerusermodalopen(mob) {
            this.usersearchalertmodals = false;
            this.createuserdetailstatus = true;
            this.userAddStepFirstStatus = true;
            this.showloaderusermodal = true;
            this.add.middlename = '';
            this.add.lastname = '';
            this.add.email = '';
            this.add.gender = '';
            this.add.birthdate = '';
            this.add.designation = '';
            this.add.usercreateimg = null;
            this.add.fulladdress = '';
            this.add.pincode = '';
            this.add.landmark = '';
            this.add.city = '';
            this.add.area = '';
            this.createpreviewurl = null;
            this.getalltitle();
            this.getUserDesignations();
            this.getRoles({ at4: 2 });
            this.getModules();
            this.getHostBranches({ ac1: this.hostId });
            this.getstates();
            this.getLoadCityApi();
            this.showloaderuserregmodal = false;
            this.add.primobile = mob;
        },
        userRegistrationModalClose() {
            this.createuserdetailstatus = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        closeSearchAlertModal() {
            this.usersearchalertmodals = false;
            this.searchmobile = '';
        },
        userDetailEditModalOpen(e) {
            this.edituserdetailstatus = true;
            this.userEditStepFirstStatus = true;
            this.userEditStepTwoStatus = false;
            this.userEditStepThrirdStatus = false;
            this.showloaderusermodal = true;
            this.edit.gender = '';
            this.edit.birthdate = '';
            this.edituser.selectedTreeValue = "";
            this.edituser.userrole = "";
            this.edituser.roletype = '';
            this.createpreviewurl = null;
            this.exitpreviewurl = null;
            // this.getcountrycode();
            this.getalltitle();
            this.getUserDesignations();
            this.getRoles({ at4: 2 });
            this.getModules();
            this.getHostBranches({ ac1: this.hostId });
            this.getstates();
            this.getLoadCityApi();
            this.existUserId = e.ad1;
            this.editHostId = e.ad2;
            this.edit.primobile = e.ad15;
            this.edit.title = {
                label: e.ad5,
                value: e.ad4,
            };
            this.edit.firstname = e.ad6;
            this.edit.middlename = e.ad7;
            this.edit.lastname = e.ad8;
            this.edit.email = e.ad18;
            if (e.ad12 != null && e.ad12 != "") {
                this.edit.birthdate = new Date(e.ad12);
            }
            if (e.ad14 == 1) {
                this.edit.gender = {
                    label: "Male",
                    value: e.ad14,
                }
            } else if (e.ad14 == 2) {
                this.edit.gender = {
                    label: "Female",
                    value: e.ad14,
                }
            } else if (e.ad14 == 3) {
                this.edit.gender = {
                    label: "Other",
                    value: e.ad14,
                }
            }
            this.edit.designation = {
                label: e.ad11,
                value: e.ad10,
            };
            if (e.ad25 != null && e.ad25 != '') {
                var labels = e.ad25.split(',');
                var values = e.ad24.split(',');
                var branchesnames = [];
                for (var i = 0; i < labels.length; i++) {
                    var tags = [];
                    tags['label'] = labels[i];
                    tags['value'] = values[i];
                    Object.assign({}, tags);
                    var tagobject = Object.assign({}, tags);
                    branchesnames.push(tagobject);
                }
                this.edit.branch = branchesnames;
            }
            if (e.ad22 != null) {
                this.edit.importprofilefile = "https://storage.googleapis.com/" + this.userstorageimgpath + "/host/userprofile/" + e.ad22;
            }
            if (e.ad26 != null && e.ad26 == '') {
                this.edituser.roletype = 2;
            } else {
                this.edituser.roletype = 1;
            }
            this.edituser.userrole = e.ad26;
            if (e.ad29 != null) {
                let arrConverted = e.ad29.split(",");
                this.edituser.selectedTreeValue = arrConverted;
                for (let index = 0; index < arrConverted.length; index++) {
                    let idxx = arrConverted[index];
                    this.edituser.selectedTreeValue = {
                        ...this.edituser.selectedTreeValue,
                        [idxx]: { checked: true, partialChecked: false },
                    };
                }
            }
            this.usertypeedit = e.ad23;
            this.edit.fulladdress = e.ad30;
            this.edit.pincode = e.ad37;
            this.edit.landmark = e.ad38;
            this.edit.state = {
                label: e.ad36,
                value: e.ad35,
            };
            this.edit.city = {
                label: e.ad34,
                value: e.ad33,
            };
            this.edit.area = {
                label: e.ad32,
                value: e.ad31,
            };
            if (e.ad39 == 1) {
                this.allow.usermobpermission = true;
            } else {
                this.allow.usermobpermission = false;
            }

            if (e.ad40 == 1) {
                this.allow.useremailpermission = true;
            } else {
                this.allow.useremailpermission = false;
            }

            if (e.ad47 == 1) {
                this.allow.useraadharpermission = true;
            } else {
                this.allow.useraadharpermission = false;
            }

            if (e.ad41 == 1) {
                this.allow.usermobapppermission = true;
            } else {
                this.allow.usermobapppermission = false;
            }

            if (e.ad42 == 1) {
                this.allow.usereditpermission = true;
            } else {
                this.allow.usereditpermission = false;
            }
        },
        userDetailEditModalClose() {
            this.edituserdetailstatus = false;
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        handleUploaduserProfile() {
            var file = this.$refs.userprofileimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.errorprofileimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorprofileimage = 'Invalid file type';
            } else {
                this.errorprofileimage = '';
                this.edit.userprofileimg = this.$refs.userprofileimg.files[0];
                this.exitpreviewurl = this.edit.userprofileimg;
            }
        },
        handleUploaduserCreate() {
            var file = this.$refs.usercreateimg.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file.size > 2048) {
                this.errorprocreateimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorprocreateimage = 'Invalid file type';
            } else {
                this.errorprocreateimage = '';
                this.add.usercreateimg = this.$refs.usercreateimg.files[0];
                this.createpreviewurl = this.add.usercreateimg;
            }
        },
        getRoles(e) {
            this.ApiService.getRoles(e).then((data) => {
                if (data.status == 200) {
                    this.userrole_list = data.data;
                } else {
                    this.userrole_list = '';
                }
            });
        },
        getHostBranches(e) {
            this.ApiService.getHostBranches(e).then((data) => {
                if (data.status == 200) {
                    this.branchList = data.data;
                } else {
                    this.branchList = '';
                }
            });
        },
        expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }
            this.expandedKeys = { ...this.expandedKeys };
        },

        collapseAll() {
            this.expandedKeys = {};
        },

        expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;
                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },

        updateuserDetailbtn() {
            this.v$.edit.$validate();
            var formData = new FormData();
            formData.append("ad1", this.existUserId);
            formData.append("ad2", this.editHostId);
            formData.append("ad15", this.edit.primobile);
            formData.append("ad4", this.edit.title.value);
            formData.append("ad5", this.edit.title.label);
            formData.append("ad6", this.edit.firstname);
            if (this.edit.middlename) {
                formData.append("ad7", this.edit.middlename);
            }
            formData.append("ad8", this.edit.lastname);
            var fullname = this.edit.title.label + ' ' + this.edit.firstname + ' ' + this.edit.middlename + ' ' + this.edit.lastname;
            formData.append('ad9', fullname);
            if (this.edit.email) {
                formData.append("ad18", this.edit.email);
            }
            formData.append('ad12', moment(this.edit.birthdate).format("YYYY-MM-DD"));
            formData.append("ad14", this.edit.gender.value);
            formData.append('ad30', this.edit.fulladdress);
            formData.append('ad37', this.edit.pincode);
            if (this.edit.landmark) {
                formData.append("ad38", this.edit.landmark);
            }
            formData.append("ad35", this.edit.state.value);
            formData.append("ad36", this.edit.state.label);
            formData.append("ad33", this.edit.city.value);
            formData.append("ad34", this.edit.city.label);
            formData.append("ad31", this.edit.area.value);
            formData.append("ad32", this.edit.area.label);
            formData.append("ad10", this.edit.designation.value);
            formData.append("ad11", this.edit.designation.label);
            if (this.edit.branch) {
                formData.append('branchlist', JSON.stringify(this.edit.branch));
            }
            if (this.edit.userprofileimg) {
                formData.append("userProfileImage", this.edit.userprofileimg);
            }
            formData.append('ad23', this.usertypeedit);
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }
            if (!this.v$.edit.$error) {
                this.edituserloader = true;
                this.ApiService.addOrUpdateUser(formData).then((data) => {
                    if (data.status == 200) {
                        this.edituserloader = false;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.userEditStepFirstStatus = false;
                        this.userEditStepTwoStatus = true;
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.userEditStepFirstStatus = true;
                        this.edituserloader = false;
                    }
                });
            } else {
                this.$error;
            }
        },
        updateUserPermissionbtn() {
            this.v$.edituser.$validate();
            let fields = {};
            fields["ad1"] = this.existUserId;
            fields["ad2"] = this.editHostId;
            fields["ad15"] = this.edit.primobile;
            fields["ad6"] = this.edit.firstname;
            if (this.edituser.roletype) {
                fields["role_type"] = this.edituser.roletype;
            }
            if (this.edituser.roletype == 1) {
                fields["ad26"] = this.edituser.userrole;
            }
            if (this.edituser.roletype == 2) {
                fields["modules"] = this.edituser.selectedTreeValue;
            }

            if (!this.v$.edituser.$error) {
                this.edituserloader = true;
                this.ApiService.updateUserPermissions(fields).then((items) => {
                    if (items.status == 200) {
                        this.edituserloader = false;
                        this.$toast.open({
                            message: items.message,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.userEditStepTwoStatus = false;
                        this.userEditStepThrirdStatus = true;
                    } else {
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.edituserloader = false;
                        this.userEditStepTwoStatus = true;
                    }
                });
            }
        },
        updateClientUserPermission() {
            let fields = {};
            fields["ad1"] = this.existUserId;
            fields["ad2"] = this.editHostId;
            if (this.allow.usermobpermission == true) {
                fields["ad39"] = 1;
            } else if (this.allow.usermobpermission == false) {
                fields["ad39"] = 0;
            }

            if (this.allow.useremailpermission == true) {
                fields["ad40"] = 1;
            } else if (this.allow.useremailpermission == false) {
                fields["ad40"] = 0;
            }

            if (this.allow.useraadharpermission == true) {
                fields["ad47"] = 1;
            } else if (this.allow.useraadharpermission == false) {
                fields["ad47"] = 0;
            }

            if (this.allow.usermobapppermission == true) {
                fields["ad41"] = 1;
            } else if (this.allow.usermobapppermission == false) {
                fields["ad41"] = 0;
            }

            if (this.allow.usereditpermission == true) {
                fields["ad42"] = 1;
            } else if (this.allow.usereditpermission == false) {
                fields["ad42"] = 0;
            }
            this.editpermissionloader = true;
            this.ApiService.updateuserallowpermissions(fields).then((items) => {
                if (items.status == 200) {
                    this.editpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });

                    this.userEditStepThrirdStatus = false;
                    this.edituserdetailstatus = false;
                    if (this.editHostId == this.user_storage_fk) {
                        this.logout();
                    } else {
                        this.redirecttouserlisting();
                    }
                } else {
                    this.editpermissionloader = false;
                    this.$toast.open({
                        message: items.message,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
        handleEditGender() {
            if (this.edit.title.value == 1) {
                this.edit.gender = {
                    label: "Male",
                    value: 1,
                }
            } else if (this.edit.title.value == 2 || this.edit.title.value == 3) {
                this.edit.gender = {
                    label: "Female",
                    value: 2,
                }
            } else {
                this.edit.gender = '';
            }
        },
        handleAddGender() {
            if (this.add.title.value == 1) {
                this.add.gender = {
                    label: "Male",
                    value: 1,
                }
            } else if (this.add.title.value == 2 || this.add.title.value == 3) {
                this.add.gender = {
                    label: "Female",
                    value: 2,
                }
            } else {
                this.add.gender = '';
            }
        },
        
        redirecttouserlisting() {
            this.$router.push("/user/listing");
        },
        createVoterEditDesignation(event) {
            if (event && event.value) {
                if (Number.isInteger(event.value)) {
                    this.edit.designation = { value: event.value, label: event.label };
                } else {
                    this.edit.designation = { value: 0, label: event.label };
                }
            }
        },
        createVoterAddDesignation(event) {
            if (event && event.value) {
                if (Number.isInteger(event.value)) {
                    this.add.designation = { value: event.value, label: event.label };
                } else {
                    this.add.designation = { value: 0, label: event.label };
                }
            }
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-user-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-user-section-outer::-webkit-scrollbar {
    display: block;
}

.search-user-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-user-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.user-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    width: 115px;
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.user-alert-group {
    font-family: "AcuminPro-Regular";
    font-size: 16px;
    letter-spacing: 1px;
    color: #303336;
}

.alert-body-bg {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.user-already-user-status {
    padding: 8px 16px;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
    margin-bottom: 15px;
    color: #63957d;
    font-weight: 700;
    font-family: "AcuminPro-SemiBold";
}

.divider-custom-list {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed #d4e4f3;
}

.branch-list-split {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.user-profile-table-col {
    width: 10%;
}

.user-name-table-col {
    width: 30%;
}
</style>